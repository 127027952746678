/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
import React from "react"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import styled from "styled-components"
import Searchpage from "../components/Search/Searchpage"
import { getQueryVariable } from "../lib/util"

const SearchPage = ({ location }) => {
  const post_id = -1 //-1 means no post id
  const query = getQueryVariable("query")
  const searchPlaceholderText = "Search for any brand, size or comfort level..."

  return (
    <Layout location={location}>
      <SEO
        title={seo.yoastTitle}
        location={location}
        post_id={post_id}
        seo={seo}
      />
      <StyledSearchPage>
        <div className="searchText">
          <h1>Search Results</h1>
        </div>
        <Searchpage className="search-page-container" query={query} />
      </StyledSearchPage>
    </Layout>
  )
}

export default SearchPage

// ======================
//       HELPERS
// ======================

const seo = {
  yoastTitle: "Search",
  yoastDescription: "",
  yoastMeta: `[
    {"property":"og:title","content":"Search"},
    {"property":"og:site_name","content":"The Mattress Warehouse"}
  ]`,
  yoastSchema: "",
  woocommerceSchema: "",
}

// ======================
//      STYLES
// ======================

const StyledSearchPage = styled.div`
  min-height: 330px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .search-page-container {
      display: flex;
      flex-direction: column;
    }
    .product-card {
      margin: 0;
      grid-template-rows: 210px 70px 20px 1px 80px;
    }
    input {
      width: 315px;
      margin: 0 auto 20px auto;
      padding-left: 20px;
    }
  }
  div.searchText {
    max-width: 1240px;
    margin: 0 auto;
    padding-left: 20px;
    & h1 {
      margin: 40px 0;

      @media (max-width: ${({ theme }) => theme.breakSmall}) {
        margin: 0;
        padding: 0;
      }
    }
    @media only screen and (max-width: ${({ theme }) => theme.breakSmall}) {
      width: 315px;
      margin: auto;
    }
  }
`
