import React, { memo, useState, useEffect, useContext, useRef } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import searchIcon from "../../images/icons/search-dark.svg"
import * as AppSearchClient from "@elastic/app-search-javascript"
import ProductContext from "../../context/ProductContext"
import ProductList from "../Products/ProductList"
import BlogCard from "../../zzz/organisms/blog/BlogCard"
import SearchInput, { filterSearchResults } from "./SearchInput"
import SearchPageHitsList from "./SearchPageHitList"

const searchPlaceholderText = "Search for any brand, size or comfort level..."

const Searchpage = memo(({ query = "", className = "" }) => {
  const { allProducts } = useContext(ProductContext)
  const searchPageQueryData = useStaticQuery(SEARCH_PAGE_QUERY)
  const allPosts = searchPageQueryData?.allLocalWpGraphQlPosts?.nodes

  const [searchQuery, setSearchQuery] = useState(query)
  const [searchResponse, setSearchResponse] = useState(null)
  const [searchResults, setSearchResults] = useState({
    posts: [],
    brands: [],
    products: [],
    pages: [],
  })

  const [emptySearch, setEmptySearch] = useState(true)

  const index = useRef(
    AppSearchClient.createClient({
      searchKey: process.env.GATSBY_ELASTICSEARCH_API_KEY,
      endpointBase: process.env.GATSBY_ELASTICSEARCH_API_URL,
      engineName: process.env.GATSBY_ELASTICSEARCH_INDEX_NAME,
    })
  )

  // once index is loaded, let's check if a query string was given and perform a search with it
  useEffect(() => {
    if (query != "") {
      // query given, do default search
      performSearch({ target: { value: query } })
    }
  }, [index])

  // Perform search on the input field string
  // We avoid many states here because they slow things down
  const performSearch = async (event) => {
    if (index.current) {
      const newQuery = event.target.value
      setSearchQuery(newQuery)
      const options = {
        page: { size: 32 },
      }
      if (!newQuery || newQuery.length < 3) {
        setSearchResults(filterSearchResults(null))
        setEmptySearch(true)
      } else {
        index.current
          .search(newQuery, options)
          .then((results) => {
            const filteredHits = filterSearchResults(results)
            setSearchResponse(results)

            // unpack filtered results
            const filteredPages = filteredHits.pages
            const filteredBrands = filteredHits.brands

            const filteredProducts = []
            for (
              let filtered_prod_i = 0;
              filtered_prod_i < filteredHits.products.length;
              ++filtered_prod_i
            ) {
              const hit_id = filteredHits.products[filtered_prod_i].id.raw
              for (
                let all_prod_j = 0;
                all_prod_j < allProducts.length;
                ++all_prod_j
              ) {
                if (hit_id == allProducts[all_prod_j].wordpress_id) {
                  filteredProducts.push(allProducts[all_prod_j])
                  break
                }
              }
            }

            const filteredPosts = []
            for (
              let filtered_post_i = 0;
              filtered_post_i < filteredHits.posts.length;
              ++filtered_post_i
            ) {
              const hit_slug = filteredHits.posts[filtered_post_i].id.raw
              for (
                let all_post_j = 0;
                all_post_j < allPosts.length;
                ++all_post_j
              ) {
                if (hit_slug == allPosts[all_post_j].slug) {
                  filteredPosts.push(allPosts[all_post_j])
                  break
                }
              }
            }

            // update search state based on filter results
            setSearchResults({
              products: filteredProducts,
              brands: filteredBrands,
              pages: filteredPages,
              posts: filteredPosts,
            })
            // set emptySearch to true if results.query is falsy
            setEmptySearch(!newQuery)
          })
          .catch((error) => {
            console.error(`Error caught during search:`)
            console.error(error)
          })
      }
    }
  }

  return (
    <SearchContainer className={className}>
      <StyledElasticSearchInput
        searchPlaceholderText={searchPlaceholderText}
        query={query}
        performSearch={performSearch}
      />
      {emptySearch ? null : (
        <>
          <h3>Products</h3>
          <ProductSearchResultsStyling>
            {!searchResults.products.length ? (
              <p>No products match your query</p>
            ) : (
              <ProductList
                disableProductOrderSort
                productList={searchResults.products}
                productsPerLoad={8}
                sizesFilter={false}
                searchAnalyticsFunction={(productId) => {
                  if (searchResponse?.info?.meta?.request_id) {
                    const searchAnalyticsObject = {
                      query: searchQuery,
                      documentId: productId,
                      requestId: searchResponse?.info?.meta?.request_id,
                    }
                    index.current
                      .click(searchAnalyticsObject)
                      .catch((error) => {
                        console.error(`search click error: ${error}`)
                      })
                  }
                }}
              />
            )}
          </ProductSearchResultsStyling>
          <h3>Brands</h3>
          <PagesSearchResultsStyling>
            {!searchResults.brands.length ? (
              <p>No brands match your query</p>
            ) : (
              <SearchPageHitsList
                pages={searchResults.brands}
                client={index.current}
                searchQuery={searchQuery}
                searchResponse={searchResponse}
              />
            )}
          </PagesSearchResultsStyling>
          <h3>Pages</h3>
          <PagesSearchResultsStyling>
            {!searchResults.pages.length ? (
              <p>No pages match your query</p>
            ) : (
              <SearchPageHitsList
                pages={searchResults.pages}
                client={index.current}
                searchQuery={searchQuery}
                searchResponse={searchResponse}
              />
            )}
          </PagesSearchResultsStyling>
          <h3>Blogs</h3>
          <PostsSearchResultsStyling>
            {!searchResults.posts.length ? (
              <p>No posts match your query</p>
            ) : (
              <BlogResultsStyling>
                {searchResults.posts.map((post) => (
                  <BlogCard
                    key={post.id}
                    post={post}
                    searchAnalyticsFunction={() => {
                      if (searchResponse?.info?.meta?.request_id) {
                        const searchAnalyticsObject = {
                          query: searchQuery,
                          documentId: post.slug,
                          requestId: searchResponse?.info?.meta?.request_id,
                        }
                        index.current
                          .click(searchAnalyticsObject)
                          .catch((error) => {
                            console.error(`search click error: ${error}`)
                          })
                      }
                    }}
                  />
                ))}
              </BlogResultsStyling>
            )}
          </PostsSearchResultsStyling>
        </>
      )}
    </SearchContainer>
  )
})

export default Searchpage

const SearchContainer = styled.div`
  max-width: 1240px;
  position: relative;
  margin: 0 auto;
  padding: 0 30px;
  h3 {
    font-weight: 600;
  }
`
const ProductSearchResultsStyling = styled.div`
  position: relative;
  margin: 2rem auto;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    & > div {
      padding: 0;
    }
  }
`
const PagesSearchResultsStyling = styled.div`
  margin: 2rem auto 2.5rem;
  width: 100%;
`

const BlogResultsStyling = styled.div`
  width: 66.7%;
  margin: 0 auto;
  img {
    max-height: 209px;
    overflow: hidden;
    width: auto;
    object-fit: cover;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
  }
`
const PostsSearchResultsStyling = styled.div`
  position: relative;
  margin: 0 auto;
  h5,
  div,
  span {
    font-size: 0.88rem;
    line-height: 1.3rem;
  }
  a {
    font-size: 1rem;
    line-height: 2rem;
  }
`

const StyledElasticSearchInput = styled(SearchInput)`
  max-width: 1240px;
  margin: 0 auto;
  padding-left: 20px;
  & h1 {
    margin: 40px 0;
  }
  width: 100%;
  margin: 0 auto 60px auto;
  padding: 15px 45px 15px 15px;
  border-radius: 3px;
  border: 1px solid #3e4b5c;
  background-color: white;
  box-shadow: rgba(97, 97, 97, 0.25) 0px 13px 27px 0px;
  color: black;
  font-size: 16px;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: 99% center;
  -webkit-text-fill-color: black;
  &::placeholder {
    color: rgba(0, 0, 0, 0.7);
  }
  @media only screen and (max-width: 768px) {
    width: 80%;
    padding-left: 0;
  }
`

// ===============
//      QUERY
// ===============
const SEARCH_PAGE_QUERY = graphql`
  {
    allLocalWpGraphQlPosts {
      nodes {
        id
        title
        uri
        slug
        date
        content
        excerpt
        author {
          name
          slug
          avatar {
            url
          }
        }
        featuredImage {
          sourceUrl
          imageFile {
            localFile {
              childImageSharp {
                fluid(maxHeight: 180) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
